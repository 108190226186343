<template>
  <div class="main-content affiliate affiliateSign">
    <h4 class="textTitle">註冊成為聯盟夥伴</h4>
    <div class="banner">
      <div class="banner-ico"></div>
    </div>

    <div class="affiliateSign-form">
      <el-form
          ref="contactForm"
          :model="contactForm"
          :rules="contactRules"
      >
        <el-form-item prop="first_name" label="名字">
          <el-input v-model="contactForm.first_name"></el-input>
        </el-form-item>
        <el-form-item prop="last_name" label="姓氏">
          <el-input v-model="contactForm.last_name"></el-input>
        </el-form-item>
        <el-form-item prop="email" label="電子郵件">
          <el-input v-model="contactForm.email"></el-input>
        </el-form-item>
        <el-form-item prop="message" label="訊息">
          <el-input
              v-model="contactForm.message"
              type="textarea"
              :rows="16"
              resize="none"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <p class="tremLink">
            <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M11 7.5V11.5M11 15.5H11.01M21 11.5C21 17.0228 16.5228 21.5 11 21.5C5.47715 21.5 1 17.0228 1 11.5C1 5.97715 5.47715 1.5 11 1.5C16.5228 1.5 21 5.97715 21 11.5Z"
                  stroke="#EC221F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <span>
         申請加入前，請<a style="color: #00a0e9" href="https://store.super.esimart.com/terms" target="_blank">點選我</a>了解更多聯盟夥伴條款與合作細則
       </span></p>
          <button class="button affiliate-btn" type="button" :disabled="butDisabled" @click="submit">
            提交
          </button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import {emailContactUs} from "@/request/api"

export default {
  name: "home",
  components: {},
  data() {
    return {
      search: '',
      butDisabled: false,
      contactForm: {
        // problem_class_id: null,
        first_name: '',
        last_name: '',
        email: '',
        message: ''
      },
      contactRules: {
        // problem_class_id: { required: true, message: 'Class is required', trigger: 'blur' },
        first_name: {required: true, message: '名字是必填項', trigger: 'blur'},
        last_name: {required: true, message: '姓氏是必填項', trigger: 'blur'},
        email: [
          {required: true, message: '電子郵件是必填項', trigger: 'blur'},
          {type: 'email', message: '請輸入正確的電子郵件地址', trigger: 'blur'}
        ]
      }
    };
  },
  created() {

  },
  methods: {

    submit() {
      var that = this
      this.$refs.contactForm.validate(valid => {
        if (valid) {
          // //發送聯繫email
          let data = that.contactForm;
          let myheaders = {headers: {"pc": true, "content-type": "multipart/form-data"}};
          emailContactUs(data, myheaders).then(function (res) {
            if (res.code == 200 || res.code == "200" || res.code == "ok") {
              that.$alert(res.msg, '', {
                confirmButtonText: '確定',
                customClass: 'affiliate-alert',
                callback: action => {
                  // 確定後執行**
                  that.$router.push('/');
                }
              });
            } else {
              that.$message.error(res.msg);
            }
          });
        } else {

          return false
        }
      })
    },
  },
  watch: {}
}
</script>

<style lang="less" scoped>
p {
  color: #ffffff;
}
.tremLink{
  display: flex;
  align-items: stretch;
  line-height: 22px;
  gap: 10px;
  font-size: 20px;
}
.affiliateSign-form .affiliate-btn{
  margin-top: 40px;
}

@media screen and(max-width: 768px) {
  .tremLink{
    font-size: 16px;
  }
}
</style>
